<template>
    <modal ref="modalAnadirCedis" titulo="Añadir cedis" no-aceptar adicional="Guardar" @adicional="anadirCedisAPromocion">
        <div class="row mx-0 justify-center">
            <div class="col-10 mb-4">
                <small class="pl-3 text-general f-12">
                    Seleccionar cedis
                </small>
                <el-select v-model="value" class="w-100" size="small" @change="agregarAcedis">
                    <el-option
                    v-for="item in cedisDisponibles"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-8">
                <div v-for="(data, index) in cedisAgregar" :key="index" class="row mx-0 align-items-center mb-1">
                    <div class="col tres-puntos pl-0 text-general f-16">
                        {{ data.nombre }}
                    </div>
                    <i class="icon-delete-trash f-20 text-general" @click="noAgregarCedis(index)" />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Carpetas from '~/services/promociones/promociones_carpetas_admin'
export default {
    data(){
        return {
            cedisDisponibles: [],
            cedisAgregar: [],
            value: '',
            id_promocion: this.$route.params.idPromocion,
        }
    },
    methods: {
        toggle(){
            this.getListaCedisPosibles()
            this.cedisAgregar = []
            this.$refs.modalAnadirCedis.toggle();
        },
        async getListaCedisPosibles(){
            const { data } = await Carpetas.getListaCedisDisponibles(this.id_promocion)
            this.cedisDisponibles = data.cedis
        },
        async anadirCedisAPromocion(){
            const payload = {
                id_promocion : this.id_promocion,
                cedisAgregar: this.cedisAgregar
            }
            const { data } = await Carpetas.anadirPromoCedis(payload)
            this.notificacion('Éxito', data.mensaje, 'success')
            this.$emit('actualizado')
            this.$refs.modalAnadirCedis.toggle();
        },
        agregarAcedis(item){
            this.nuevoCedis = this.cedisDisponibles.findIndex(i => i.id == item);
            this.cedisAgregar.push(this.cedisDisponibles[this.nuevoCedis])
        },
        noAgregarCedis(item){
            this.cedisAgregar.splice(item, 1)
        },
    }
}
</script>